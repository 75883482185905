<template>
    <div class="card">
        <section id="content" class="col-12 xl:col-12">
            <div id="content-header-navbar" class="flex flex-wrap align-items-center justify-content-between card-container pb-3">
                <strong id="brand">Classificado</strong>
                <div class="right">                    
                    <div class="flex align-items-center">
                        <Button label="Voltar" icon="pi pi-angle-double-left" class="p-button-success" @click="voltar()" />
                        <Button class="p-button-warning ml-1" v-if="classificado.matriculaPulicador === matricula" label="Editar" icon="pi pi-pencil" @click="editar"/>
                        <Button class="p-button-danger  ml-1" v-if="classificado.matriculaPulicador === matricula" label="Excluir" icon="pi pi-trash" @click="deleteClassificado"/>
                    </div>          
                </div>               
            </div> 

            <div class="grid">
                <div class="col-8">            
                    <div id="content-galeria" class="col-12">
                        <h5>{{ classificado.titulo}}</h5>
                        <p>{{ classificado.texto }}</p>
                    </div>

                    <div id="content-galeria" class="grid m-2">
                        <div class="col-12 xl:col-6">
                            <Galleria id="content-galeria-carrossel" :value="classificado.anexos" :responsiveOptions="responsiveOptions" :numVisible="10" 
                            :showThumbnails="false" :showIndicators="true" :showItemNavigators="true" :showIndicatorsOnItem="true">
                                <template #item="slotProps">                            
                                    <img :src="'data:image/jpg;base64,' + slotProps.item.base64" :alt="slotProps.item.alt"  />
                                </template>
                            </Galleria>
                        </div>
                        <div id="content-publicador" class="col-12 xl:col-6">
                            <h5>{{ classificado.nomePulicador }}</h5>
                            <span><strong>Fone contato:</strong> {{ classificado.telefone }}</span>
                            <span><strong>Regional:</strong> {{ classificado.cidadePulicador }}</span>
                            <span><strong>Visualizações:</strong> {{ classificado.classificadoViews }}</span>
                            <span><strong>Classificado Id:</strong> {{ classificado.id }}</span>
                            <span><strong>Preço:</strong> {{ classificado.valor != null 
                                    ? formatarMoeda(classificado.valor) : 'Entrar em contato para mais informações' }}</span>
                            <hr />
                        </div>                             
                    </div>  
                </div>
            </div>
        </section>  
    </div>    
</template>

<script>
    import ClassificadoServices from "../../service/ClassificadoServices";
    import Comentarios from './Comentarios.vue';
    import Formatacao from '../../utilities/Formatacao';

    export default {
        components: { Comentarios },
        props: {
            matricula: {
                type: Number,
                required: true
            },

            id: {
                type: Number,
                required: false
            }
        },

        data(){
            return {
                classificado: {},                
                responsiveOptions: [
                    {
                        breakpoint: '1920px',
                        numVisible: 4
                    },
                    {
                        breakpoint: '1280px',
                        numVisible: 3
                    },
                    {
                        breakpoint: '1024px',
                        numVisible: 2
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 3
                    },
                    {
                        breakpoint: '560px',
                        numVisible: 1
                    }
                ]
            }
        },

        methods: {
            getClassificado(){
                ClassificadoServices.getDetalhes(this.id)
                    .then(({ data }) => {
                        if(data) {
                            this.classificado = data
                        }
                        else {
                            this.voltar();
                        }
                    })
                    .catch(error => {
                        this.voltar();
                });
            },            

            deleteClassificado(){
                ClassificadoServices.inativarClassificado(this.id)
                    .then(({ data }) => { 
                        this.voltar();

                        this.$toast.add({
                            severity:'success', 
                            summary: 'sucesso', 
                            detail:'Classificado excluido com sucesso.', 
                            life: 3000
                        });                   
                    })
                    .catch(error => {
                        this.voltar();

                        this.$toast.add({
                            severity:'warn', 
                            summary: 'Falha', 
                            detail:'Não foi possivel excluir o classificado!', 
                            life: 3000
                        });
                });
            },

            getDados(){
                const dados = {}

                dados['texto'] = this.classificado.comentario;

                const classificado = {
                    id: this.classificado.id,
                };                

                if(this.classificado.cooperadoPublicou){
                    const cooperadoPublicador = {
                        matricula: this.matricula,
                    }
                    dados['cooperadoPublicador'] = cooperadoPublicador;
                }else {
                    const usuarioPublicador = {
                        matricula: this.matricula,
                    }
                    dados['usuarioPublicador'] = usuarioPublicador;
                }

                dados['classificado'] = classificado;
                return dados;
            },

            editar() {
                this.$router.push(`/classificado/editar/${this.id}`);
            },

            voltar(){
                this.$router.push(`/classificados`);
            },

            formatarMoeda(valor) {
                return Formatacao.formatCurrency(valor);
            },
        },
        created(){
            this.getClassificado();
        }         
           
    }
</script>