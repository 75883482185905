import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const CLASSIFICADOS_API_BASE_URL = PortalCooperadoUtils.apiCooperadoClassificado;
const RESOURCE_V1 = `/v1/classificados`;

class ClassificadosService {
    salvar(data) {   
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        return axios.post(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}`, data, config);
    }

    getClassificadoId(id) {
        return axios.get(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}/${id}`);
    }

    getDetalhes(id) {
        return axios.get(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}/detalhes/${id}`);
    }

    getClassificados(pagina, qtdRegistro) {
        return axios.get(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}`, {
            params: {
                page: pagina,
                size: qtdRegistro
            }
        });
    }

    inativarClassificado(id) {
        return axios.put(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}/delete/${id}`);
    }

    anexoInativar(id) {
        return axios.put(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}/anexo/delete/${id}`);
    }
}

export default new ClassificadosService();
