<template>
    <div class="card justify-content-between">
        <div class="grid col-12 align-items-center">
            <div class="grid col-11 lx-hidden">
                <i class="pi pi-wallet" style="font-size: 2.5rem"></i>
                <h1 style="margin:0px 8px;">Classificado</h1>
            </div>

            <div class="col-12 md:col-1"> 
                <Button label="Publicar" icon="pi pi-plus" class="buttom p-button-success" @click="$emit('criarClassifiado')" />
            </div>
        </div>
        <hr />
        
        <div v-if="classificados.length === 0" class="justify-content-between  align-items-center m-3">
            <h4>Nenhum classificado encontrado.</h4>
        </div> 

        <div class="justify-content-center col-12">
            <div v-if="classificados.length > 0" class="grid" >
                <div v-for="item in classificados" id="classificado-list" class="sm-12 md:col-4 lg:col-3 xl:col-2 m-1" :key="item.id"> 
                    <Image id="preview" v-bind:src="'data:image/jpg;base64,'+ item.thumbnail"/>                
                    <h5>{{ item.titulo }}</h5>
                    <div class="classificado-list-info">
                        <span></span><strong>Categoria: </strong> {{ item.categoria.nome }}
                        <span></span><strong>Visualizações: </strong> {{ item.views }}
                        <span></span><strong>Data Publicação: </strong> {{formatDate(item.publicado) }}   
                        <span></span><strong>Preço: </strong> {{ item.valor != null 
                                    ? formatarMoeda(item.valor) : 'Entrar em contato para mais informações' }}
                    </div>
                    <div id="classificado-list-footer" class="col-12">
                        <Button class="p-button-success" label="Visualizar" icon="pi pi-eye" @click="visualizar(item.id)"/>
                    </div>          
                </div>
            </div>

            <Paginator
                :rows="qtdRegistro"
                :totalRecords="totalRegistro"
                :rowsPerPageOptions="[3, 6, 9, 18, 36]"
                @page="onPage">
            </Paginator>
        </div>
    </div> 
</template>

<script>
    import ClassificadoServices from "../../service/ClassificadoServices"
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'Classificados',
        emits: ['criarClassifiado'],

        props: {
            matricula: {
                type: Number,
                required: true
            }
        },

        data(){
            return {
                pagina: 0,
                qtdRegistro: 9,
                totalRegistro: 0,
                classificados: [],                
            }
        },

        created(){
            this.getClassificados();        
        },

        methods: {  
            getClassificados() {         
                ClassificadoServices.getClassificados(this.pagina, this.qtdRegistro)
                    .then(({ data }) => {
                        if(data) {
                            this.classificados = data.content;
                            this.qtdRegistro = data.pageable.pageSize;
                            this.totalRegistro = data.totalPages * this.qtdRegistro;
                        }
                    });
            },

            visualizar(id) {
                this.$router.push(`/classificado/${id}`);
            },

            formatDate(data) {
                return Formatacao.formatDate(data);
            },        

            onPage(event) {
                this.pagina = event.page;
                this.qtdRegistro = event.rows;
                this.getClassificados();
            },

            formatarMoeda(valor) {
                return Formatacao.formatCurrency(valor);
            },
        }
    }
</script>