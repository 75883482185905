import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const CLASSIFICADOS_API_BASE_URL = PortalCooperadoUtils.apiCooperadoClassificado;
const RESOURCE_V1 = `/v1/categorias`;

class CategoriaService {
    getCategorias() {
        return axios.get(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}`);
    }

    getCategoriaNome(nome) {
        return axios.get(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}/buscar/${nome}`);
    }
}
export default new CategoriaService();