<template>
    <div id="content-galeria-coments" class="bg-white col-4 mt-3">
        <h5 v-if="totalRegistro > 0">Comentários: {{totalRegistro}}</h5>         
        <div id="grid content-coments-component" class="col-12" v-for="item in comentarios" :key="item.id"> 
            <div id="content-coments-component">         
                <h5 v-if="item.nomePublicador" > {{ item.nomePublicador }}</h5>
                <span><strong>Publicado em:</strong> {{ formatDateHora(item.publicado) }}</span>
                <p>{{ item.texto }}</p> 
                <div class="flex justify-content-end">  
                    <span v-if="!deletar" @click="deletar='true'"><i class="pi pi-ellipsis-h"></i></span>
                    <span v-if="(item.podeDeletar && deletar)" @click="dialogDeletar(item.id)"><i class="pi pi-trash"></i></span>
                </div> 
            </div>
            
        </div>
        <span class="flex justify-content-end m-4" @click="maisComentarios">Mais</span>
    </div> 
</template>
    
<script>
    import ComentarioService from "../../service/ComentarioService";
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'Comentarios',
        props: {
			matricula: {
				type: Number,
				required: true
			},

			classificadoId: {
				type: Number,
				required: true
			},
        },

        data() {
            return {
                deletar: false,
                comentario: null,
                comentarios: [], 

                pagina: 0,
                totalPaginas: null,
                qtdRegistro: 3,
                totalRegistro: 0,
            }
        },

        methods: {
            getComentarios() {
                ComentarioService.getComentarios(this.classificadoId, this.pagina, this.qtdRegistro)
                    .then(({ data }) => {
                        if(data) {                            
                            this.comentarios = data.content;
                            this.totalPaginas = data.totalPages;
                            this.qtdRegistro = data.pageable.pageSize;
                            this.totalRegistro = data.totalPages * this.qtdRegistro;
                        }
                    });
            },

            formatDateHora(data) {
                return Formatacao.formatDateCustom(data, 'DD/MM/YYYY HH:mm');
            },

            dialogDeletar(id){
                ComentarioService.deletar(id)
                    .then(({ data }) => {
                        if(data) {                            
                            this.getComentarios();
                            this.deletar = false;
                        }
                    });
            },

            maisComentarios(){
                if(this.pagina <= this.totalPaginas){
                    this.pagina = this.pagina + 1;
                }else{
                    this.pagina = 0;
                }
                this.qtdRegistro = 5;
            },
        },

        created() {
            this.getComentarios();
        },
    }
    
</script>
