import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const CLASSIFICADOS_API_BASE_URL = PortalCooperadoUtils.apiCooperadoClassificado;
const RESOURCE_V1 = `/v1/comentarios`;

class ComentarioService {
    getComentarios(id, pagina, qtdRegistro) {
        return axios.get(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}/${id}`, {
            params: {
                page: pagina,
                size: qtdRegistro
            }
        });
    }

    salvar(data) {   
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        return axios.post(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}`, data, config);
    }

    deletar(id) {
        return axios.put(`${CLASSIFICADOS_API_BASE_URL}${RESOURCE_V1}/${id}`);
    }
}

export default new ComentarioService();
