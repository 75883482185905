<template>
   <div class="card">
        <div class="title col-12 xl:col-8">
            <div class="grid justify-content-between align-items-center">
                <div class="grid col-9 align-items-center">
                    <h5 class="m-4">Publicar Classificado</h5>
                </div>

                <div class="col-12 md:col-3 lx-hidden"  xl:style="text-align: right;">                    
                    <div class="flex align-items-center">
                        <Button label="Cancelar" icon="pi pi-times" class="buttom p-button p-component p-button-danger mr-2" @click="cancelar()" />
                        <Button label="Salvar" icon="pi pi-save" class="buttom p-button p-component p-button-success" @click="salvar()" />
                    </div>
                </div>
            </div>  
            
            <div class="profile grid">
                <img src="../../assets/img/produtor.jpeg"/>
                <div class="info">
                    <strong>{{ cooperado.nome }}</strong>
                    <strong>{{ cooperado.email }}</strong>
                    <strong>{{ cooperado.estabelecimento }} - {{ cooperado.cidade}}</strong>
                </div>
            </div>

            <Message class="lx-hidden" severity="info" :closable="true">Os classificados são de responsabilidade exclusiva de seus autores. Leia as perguntas mais frequentes para saber o que é impróprio ou ilegal.</Message>
        

            <div class="grid m-1">
                    <div class="col-7">
                        <label for="titulo"><strong>Título</strong></label>
                        <InputText type="text" :class="['w-full', 'mt-1', {'p-invalid': tituloNull }]" id="titulo" v-model="classificado.titulo" maxlength="39"/>
                    </div>

                    <div class="col-5">
                        <label for="contato"><strong>Contato</strong></label>
                        <InputMask class="w-full mt-1" id="contato" v-model="classificado.telefone" mask="(99) 99999-9999" />
                    </div>

                    <div class="col-7">
                        <label for="categoria"><strong>Categoria</strong></label>
                        <AutoComplete
                            :dropdown="true"
                            v-model="classificado.categoria" 
                            :suggestions="categoriasOptions" 
                            @complete="getCategorias($event)"
                            field="nome"
                            :class="['w-full mt-1', {'p-invalid': categoriaNull }]"
                            id="categoria"/>
                    </div>

                    <div class="col-5">
                        <label for="valor"><strong>Valor</strong></label>
                        <!-- <InputMask class="w-full mt-1" 
                                id="valor" 
                                v-model="classificado.valor" 
                                mask="#,99" 
                                style="text-align: right;" /> -->
                        <InputNumber class="w-full mt-1" 
                                v-model="classificado.valor" 
                                inputId="currency-japan" 
                                mode="currency" 
                                currency="BRL" 
                                locale="pt-BR" />
                    </div>

                    <div class="col-12">
                        <label for="texto"><strong>Texto</strong></label>
                        <Textarea :class="['w-full', 'mt-1', {'p-invalid': textoNull }]" id="texto" rows="4" v-model="classificado.texto" />
                    </div>

                    <div class="col-12">
                        <FileUpload 
                            name="demo[]"
                            chooseLabel="Foto(s)"
                            cancelLabel="Limpar"
                            :showUploadButton="false"
                            :multiple="true"
                            :maxFileSize="1000000"
                            accept="image/*"
                            ref="anexos">

                            <template #empty>
                                <p>Arraste e solte os arquivos aqui para anexar.</p>
                            </template>
                        </FileUpload>
                    </div>
                </div>  
                <Fieldset class="mr-2 ml-3">
                    <template #legend>
                        <strong>Imagens Anexadas</strong>
                    </template>
                    
                    <div v-if="(anexos.length > 0)" class="grid m-1">                        
                        <div id="anexos" v-for="item in anexos" :key="item.id" @click="confirmaAnexo(item.id)">                         
                            <img v-if="(item.base64 && item.nome != 'default')" v-bind:src="'data:image/jpg;base64,'+ item.base64" />
                            <figcaption v-if="item.base64 && item.nome != 'default'"><i class="pi pi-trash"></i></figcaption>
                        </div>
                    </div>
                </Fieldset>
        </div>
    </div>

    <Dialog v-model:visible="anexoDialog" :style="{width: '450px'}" header="Excluir Anexo" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="classificado.anexos">Tem certeza de que deseja inativar o anexo?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="anexoDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="inativarAnexo()" />
        </template>
    </Dialog>

    <Dialog v-model:visible="dialog" :style="{width: '350px'}" header="Cancelar?" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Tem certeza de que deseja cancelar?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="dialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="confirmar()" />
        </template>
    </Dialog>
</template>

<script>
    import ClassificadoServices from "../../service/ClassificadoServices";    
    import CategoriaService from "../../service/CategoriaService"
    import Storage from "../../service/StorageService";
    export default {
        props: {
            matricula: {
                type: Number,
                required: true
            },

            id: {
                type: Number,
                required: false
            }
        },

        data(){
            return {
                anexoId: null,
                dialog: false,
                cooperado: null,
                classificado: {},
                anexoDialog: false,
                categoriasOptions: [],

                anexo: null,
                anexos: [],

                tituloNull: false, 
                categoriaNull: false, 
                textoNull: false,
                valorNull: false,
            }
        },

        created(){
            this.cooperado = Storage.getUser();            
            this.getClassificados();
        },

        methods: {  
            getClassificados() {
                if(this.id == null) return;               
                ClassificadoServices.getDetalhes(this.id)
                    .then(({ data }) => {
                        if (data) {
                            this.classificado = data;     
                            this.anexos = data.anexos;              
                        }
                });
                
            },

            getCategorias(event){              
                const nome = !event.query.trim().length ? 'null' : event.query;
                CategoriaService.getCategoriaNome(nome).then(({ data }) => {
                    if (data) {
                        this.categoriasOptions = data;                         
                    }
                }) 
                .catch(error => {
                    console.error(error);
                    this.categoriasOptions = [];
                });            
            },

            async getDados(){ 
                const anexo = [];
                for(let file of this.$refs.anexos.files) {
                    if(anexo.length < 2){
                        const base64 =  await this.getBase64(file);
                        anexo.push({
                            nome: file.name,
                            base64: base64
                        });
                    };
                }  
                this.classificado['cooperadoPublicador'] = {
                    matricula: this.matricula
                };
                this.classificado['anexos'] = anexo;  
            },

            async salvar(){                
                await this.getDados(); 
                if(!this.validar()) return;

                ClassificadoServices.salvar(this.classificado)
                    .then(({ data }) => {
                        if(data) {
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Sucesso', 
                                detail:'Classificado cadastrado com sucesso, ele aparecerá em breve.', 
                                life: 9000
                            });  

                            this.getClassificados();
                        }
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Falha', 
                            detail:'Falha ao publicar seu classificado!', 
                            life: 3000
                        });
                    });                    
                    this.confirmar();
                    
            },

            cancelar() {
                this.dialog = true;
            },

            confirmar(){
                if(this.id) {
                    this.$router.push(`/classificados`);
                }
                else {
                    this.$emit('criarClassifiado');
                }
            },

            confirmaAnexo(id){
                this.anexoId = id;
                this.anexoDialog = true;              
            },

            inativarAnexo(){                
                ClassificadoServices.anexoInativar(this.anexoId)
                    .then(({ data }) => {
                        if (data) {
                            this.anexoDialog = false;
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Sucesso', 
                                detail:'Anexo inativado com sucesso!', 
                                life: 3000
                            });
                            this.getClassificados();
                        }
                    }) 
                    .catch(error => {
                        this.anexoDialog = false; 

                        this.$toast.add({
                            severity:'error', 
                            summary: 'Falha', 
                            detail:'Falha ao inativar anexo!.', 
                            life: 3000
                        });
                    });
            },

            getAnexos() {
                return new Promise(async (resolve, reject) => {
                    try {
                        const anexos = [];

                        for(let file of this.$refs.anexos.files) {
                            const base64 =  await this.getBase64(file);
    
                            anexos.push({
                                nome: file.name,
                                base64: base64
                            });
                        }

                        return resolve(anexos);
                    }
                    catch(error) {
                        return reject(error);
                    }
                });
            },

            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = function () {
                        const result = reader.result;

                        if(typeof result === 'string') {
                            const base64 = result.substring(result.indexOf(',') + 1, result.length);
                            return resolve(base64);
                        }

                        return resolve(result);
                    };
                
                    reader.onerror = function (error) {
                        return reject(error);
                    };

                    reader.readAsDataURL(file);
                });
            },  
            
            validar() {
                let isValid = true;

                if(!this.classificado.titulo) {
                    this.tituloNull = true;
                    isValid = false;
                }

                if(!this.classificado.categoria) {
                    this.categoriaNull = true;
                    isValid = false;
                }

                if(!this.classificado.texto) {
                    this.textoNull = true;
                    isValid = false; 
                }

                if (!this.classificado.valor) {
                    this.valorNull = true;
                    isValid = false; 
                }

                if(!isValid) {
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Falha', 
                        detail:'Preencher os campos obrigatórios.', 
                        life: 3000
                    });
                    return;
                }

                return isValid;
            }            
        }        
    }
</script>