<template>

    <Classificados
        v-if="!isCriarOuEditar() && classificadoId == null"
        :matricula="currentUser.matricula"
        @criarClassifiado="criarClassifiado"
    />

    <ClassificadosDetalhado
        v-if="!isCriarOuEditar() && classificadoId != null"
        :id="classificadoId"
        :matricula="currentUser.matricula"
    />

    <ClassificadosPublicar
        v-if="isCriarOuEditar()"
        :matricula="currentUser.matricula"
        :id="classificadoId"
        @criarClassifiado="criarClassifiado"
    />
    
</template>

<script lang="js">
    import Classificados from '../../components/consulta/Classificados.vue';
    import ClassificadosDetalhado from '../../components/consulta/ClassificadosDetalhado.vue';
    import ClassificadosPublicar from '../../components/consulta/ClassificadosPublicar.vue';

    export default {
        emits: ['criarClassifiado'],
        data(){
            return {
                classificadoId: null,
                isCriarClassificado: false,
                isEditarClassificado: false
            }
        },
        methods: {
            carregarClassificacao() {
                if(this.$route.name === 'editarClassificado') {
                    this.isEditarClassificado = true;
                }
                else {
                    this.isEditarClassificado = false;
                }

                const classificadoId = this.$route.params.classificadoId;
                this.classificadoId = classificadoId ? parseInt(classificadoId) : null;
            },

            criarClassifiado() {
                this.isCriarClassificado = !this.isCriarClassificado;
            },

            isCriarOuEditar() {
                return this.isCriarClassificado || this.isEditarClassificado;
            }
        },

        created() {
            this.carregarClassificacao();
        },

        updated() {
            this.carregarClassificacao();
        },

        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { Classificados, ClassificadosDetalhado, ClassificadosPublicar }
    }

</script>